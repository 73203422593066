@use 'variables' as *;

mat-dialog-container.mat-mdc-dialog-container {
  .mdc-dialog__surface {
    background-color: transparent;
  }
}

.mat-mdc-dialog-container .mdc-dialog__container .mdc-dialog__surface {
  @apply bg-background-secondary dark:bg-background-secondary;
  --mdc-dialog-container-shape: theme('borderRadius.medium');
  box-shadow: none;

  &.mat-mdc-dialog-surface {
    &:has(#git-status-indicator) {
      //set to achieve semi-transparent background on git operations
      background-color: transparent;
    }
  }
}

//set here because .dark & {} is not recognized in preview dialog style file
.preview-dialog-panel .mat-mdc-dialog-container {
  background-color: transparent;

  .dark & {
    background-color: transparent;
  }
}

//to make inner elements overflow
.cdk-overlay-pane.dialog-overflow-visible {
  .mat-mdc-dialog-surface,
  .custom-dialog-body {
    overflow: visible;
  }
}

.custom-common-dialog {
  &.width-small {
    width: $widthSmall;

    @media screen and (max-width: 800px) {
      width: $widthResizeSize;
    }
  }

  &.width-medium {
    width: $widthMedium;

    @media screen and (max-width: 1000px) {
      width: $widthResizeSize;
    }
  }

  &.width-large {
    width: $widthLarge;

    @media screen and (max-width: 1250px) {
      width: $widthResizeSize;
    }
  }

  &.width-huge {
    width: $widthHuge;
    max-width: 1375px !important; //need to override default value of 80vw
  }

  &.height-auto {
    height: auto;
  }

  &.height-medium {
    height: $heightMedium;

    @media screen and (max-height: $heightMediumBreakpoint) {
      height: $heightResizeSize;
    }
  }

  &.height-large {
    height: $heightLarge;

    @media screen and (max-height: $heightLargeBreakpoint) {
      height: $heightResizeSize;
    }
  }

  &.height-huge {
    height: $heightHuge;

    @media screen and (max-height: $heightHugeBreakpoint) {
      height: $heightResizeSize;
    }
  }
}
