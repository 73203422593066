@use 'sass:map';
@use 'mixins' as *;

$no-label-padding-compact: (
  'top': theme('spacing.1'),
  'bottom': theme('spacing.1'),
);

$no-label-padding: (
  'top': theme('spacing.2'),
  'bottom': theme('spacing.2'),
);
$with-label-padding: (
  'top': theme('spacing.6'),
  'bottom': theme('spacing.2'),
);

//appearance="fill"
.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: map.get($with-label-padding, top);
  padding-bottom: map.get($with-label-padding, bottom);

  .compact-field.custom-mat-form-wrapper & {
    padding-top: map.get($no-label-padding-compact, top);
    padding-bottom: map.get($no-label-padding-compact, bottom);
  }
}

.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: map.get($no-label-padding, top);
  padding-bottom: map.get($no-label-padding, bottom);
}

.mdc-line-ripple {
  &::before,
  &::after {
    display: none;
  }
}

.mat-mdc-form-field {
  --mat-form-field-container-text-weight: theme('fontWeight.normal');

  @apply text-body-m;
  position: relative;

  .dark & {
    --mat-form-field-container-text-weight: theme('fontWeight.normal-dark');
  }

  &-text-prefix,
  &-text-suffix,
  &-icon-prefix,
  &-icon-suffix {
    @apply text-typography-primary;

    .mdc-text-field--disabled & {
      @apply text-typography-disabled;
    }
  }

  .mdc-text-field {
    @apply rounded-standard px-3;
    @include border(var(--gray-300));

    &--invalid {
      border-color: var(--red-600);
    }

    &--filled:not(.mdc-text-field--disabled) {
      .mdc-line-ripple::before {
        display: none;
      }
    }
  }

  &:hover,
  &.mat-focused {
    .mat-mdc-form-field-focus-overlay {
      opacity: 0;

      .dark & {
        opacity: 0;
      }
    }
  }

  .mat-mdc-form-field-infix {
    min-height: auto;
  }

  .time-field.custom-mat-form-wrapper & {
    width: 100%;
  }
}

.mdc-text-field--filled.mat-form-field-disabled,
.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background: none;

  .dark & {
    background: none;
  }
}

.mdc-text-field--disabled.mdc-text-field--filled {
  background: none;
  border-color: var(--gray-200);

  .dark & {
    background: none;
    border-color: var(--gray-200);
  }
}

.custom-mat-form-wrapper {
  --mdc-filled-text-field-container-color: transparent;
  --mdc-filled-text-field-disabled-container-color: transparent;
  --mat-form-field-state-layer-color: transparent;

  .mat-mdc-form-field-subscript-wrapper {
    position: absolute;
    bottom: -1px;
  }

  .mat-mdc-form-field-subscript-dynamic-size {
    .mat-mdc-form-field-hint-wrapper,
    .mat-mdc-form-field-error-wrapper {
      position: absolute;
    }
  }

  .mat-mdc-form-field-hint {
    @apply text-typography-secondary;
  }

  .mat-mdc-form-field-bottom-align {
    font-size: theme('fontSize.body-s.0');
    line-height: 1;
    letter-spacing: 0;
    position: absolute;

    &::before {
      display: none;
    }

    &::after {
      content: '';
      display: inline-block;
      height: 12px;
      position: absolute;
      top: 0;
      left: 0;
    }

    &.mat-mdc-form-field-subscript-dynamic-size::after {
      display: none;
    }

    .mat-mdc-form-field-hint-wrapper,
    .mat-mdc-form-field-error-wrapper {
      @apply px-0;
    }
  }
}

.custom-mat-form-wrapper .mat-form-label:not(:empty) {
  @apply text-typography-primary text-body-m mb-2 gap-1;
  display: flex;
  align-items: center;

  &.custom-optional {
    &:after {
      content: '(Optional)';
      font-size: 10px;
      color: var(--gray-500);
    }
  }

  &:has(+ .mat-form-field-disabled) {
    color: theme('colors.typography.disabled');
  }
}

.custom-mat-form-wrapper {
  .mat-mdc-form-field-error {
    &::before {
      display: none;
    }
  }

  .mdc-label {
    .mat-form-label:not(:empty) {
      margin-bottom: theme('spacing.0');
    }
  }
}
