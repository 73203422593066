$content-max-width: 1200px;
$container-padding-lg: 2rem;
$container-padding-sm: 1rem;

.standard-container {
  @apply mx-auto w-full;
  max-width: calc(#{$content-max-width} + #{$container-padding-lg} * 2);
  padding-left: $container-padding-lg;
  padding-right: $container-padding-lg;

  @media (max-width: 768px) {
    max-width: calc(#{$content-max-width} + #{$container-padding-sm} * 2);
    padding-left: $container-padding-sm;
    padding-right: $container-padding-sm;
  }
}
