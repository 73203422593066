@use 'variables' as *;
@use 'mixins' as *;

//equivalent to root --gray-200 and --gray-300
$hex-dark-gray-200: #26282e;
$hex-light-gray-200: #edecef;
$hex-dark-gray-300: #373943;
$hex-light-gray-300: #d4d3d8;

.root-outlet + * {
  flex: 1;
}

.workspace-container {
  @apply bg-background-primary py-20;
  width: 100%;
  height: 100%;
  overflow-y: auto;

  &.background-ellipse {
    background-image: radial-gradient(ellipse 65% 25% at 50% 100%, var(--primary-200), var(--background-primary));
    background-repeat: no-repeat;
  }

  @include mobiles {
    @apply px-2;
  }
}

.orgs {
  .org {
    &:hover {
      background-color: rgba($hex-light-gray-200, 0.6);

      .dark & {
        background-color: rgba($hex-dark-gray-200, 0.6);
      }
    }
  }

  .orgs-new {
    @apply bg-gray-200;
  }

  .org:focus-within,
  .orgs-new:focus {
    background-color: rgba($hex-light-gray-300, 0.5);

    .dark & {
      background-color: rgba($hex-dark-gray-300, 0.5);
    }
  }
}

.background-ellipse {
  background-image: radial-gradient(ellipse 65% 25% at 50% 100%, var(--primary-200), var(--background-primary));
  background-repeat: no-repeat;
}
