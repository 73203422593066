@use 'mixins' as *;

$button-icon-container: 20px;
$button-icon-large-container: 24px;
$button-icon-size: 16px;
$button-icon-compact-size: 12px;

//Button that looks like a link
button.button-primary-link {
  color: theme('colors.primary.700');

  &:hover {
    color: theme('colors.primary.800');
  }
}

button.button-accent-link {
  color: theme('colors.yellow.800');

  &:hover {
    color: theme('colors.yellow.900');
  }
}

button.button-primary-link,
button.button-accent-link {
  font-weight: theme('fontWeight.medium');
  transition: color 0.25s ease-in;

  .dark & {
    font-weight: theme('fontWeight.medium-dark');
  }
}

//Base button
.mdc-button.mat-mdc-button-base {
  @include button-padding-set;
  --mdc-text-button-label-text-size: theme('fontSize.title-s.0');
  --mdc-text-button-label-text-weight: theme('fontWeight.medium');
  --mdc-shape-small: theme('borderRadius.standard');
  height: auto;
  min-width: unset;
  border: 1px solid;
  line-height: theme('fontSize.title-s.1');

  .dark & {
    --mdc-text-button-label-text-weight: theme('fontWeight.medium-dark');
  }

  &[mat-flat-button] {
    @include button-padding-set(theme('spacing.2'), theme('spacing.3'));
    --mdc-filled-button-label-text-size: theme('fontSize.title-s.0');
    --mdc-filled-button-label-text-weight: theme('fontWeight.medium');

    .dark & {
      --mdc-filled-button-label-text-weight: theme('fontWeight.medium-dark');
    }

    &.compact-button {
      @include button-padding-set(theme('spacing.1'), theme('spacing.3'));
    }

    &.micro-button {
      @include button-padding-set(theme('spacing.1'), theme('spacing.2'));
      --mdc-filled-button-label-text-size: theme('fontSize.title-xs.0');
      line-height: theme('fontSize.title-xs.1');
    }

    &.stretched-label .mdc-button__label {
      width: 100%;
    }
  }

  &:hover {
    .mat-mdc-button-persistent-ripple {
      &::before {
        opacity: 1;
      }
    }
  }

  &.custom-button-dropdown {
    @apply pr-2;

    .chevron-icon {
      @apply ml-3 pl-2;
      position: relative;

      &::before {
        @apply -bottom-2 -top-2;
        position: absolute;
        left: 0;
        width: 1px;
        content: '';
      }
    }
  }

  &.custom-tab-button {
    @include button-padding-set(theme('spacing.1'), theme('spacing.2'));
  }

  .mat-mdc-button-touch-target {
    height: 100%;
  }

  .mdc-button__label {
    display: flex;
    align-items: center;
    white-space: nowrap;
    max-width: 100%;
  }

  &.button-list-item {
    @apply rounded-standard p-1;
    border: 1px solid transparent;
    width: 100%;

    .mdc-button__label {
      width: 100%;
    }
  }

  y42-avatar,
  y42-entity-type-icon {
    svg {
      width: 14px;
      height: 14px;
    }
  }

  y42-avatar,
  dojo-icon,
  y42-entity-type-icon,
  fa-icon {
    + * {
      @apply ml-1;
    }
  }

  &.btn-has-separator {
    margin-right: theme('spacing.2');

    &::after {
      --width: 1px;

      content: '';
      position: absolute;
      width: var(--width);
      height: 100%;
      background-color: theme('colors.gray.200');
      right: calc((theme('spacing.2') + #{var(--width)}) * -1);
    }
  }
}

//Icon button
.mdc-icon-button.mat-mdc-icon-button.mat-mdc-button-base {
  @apply rounded-standard;
  --mdc-icon-button-state-layer-size: #{$button-icon-container};
  max-width: $button-icon-container;
  max-height: $button-icon-container;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: calc((#{$button-icon-container - $button-icon-size}) / 2);
  font-size: inherit;
  overflow: hidden;

  &.custom-button-icon-compact {
    padding: calc((#{$button-icon-container - $button-icon-compact-size}) / 2);
  }

  &.custom-button-icon-large {
    padding: calc((#{$button-icon-large-container - $button-icon-size}) / 2);
    --mdc-icon-button-state-layer-size: #{$button-icon-large-container};
    max-width: $button-icon-large-container;
    max-height: $button-icon-large-container;
  }

  //Need to check after removing UX2 (mat-datepicker-toggle uses non legacy button in UX2)
  .mat-form-field .mat-datepicker-toggle & {
    @apply rounded-standard;
    --mdc-icon-button-state-layer-size: #{$button-icon-container};
    max-width: $button-icon-container;
    max-height: $button-icon-container;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: inherit;
    padding: calc((#{$button-icon-container - $button-icon-size}) / 2);

    svg,
    img {
      --mdc-icon-button-icon-size: #{$button-icon-size};
    }
  }

  .material-icons {
    .compact-field & {
      font-size: 18px;
    }
  }

  //for UX2 and UX3
  &.mat-calendar-previous-button::after,
  &.mat-calendar-next-button::after {
    margin: 5px;
  }

  &:focus-visible {
    border: 1px solid;
  }

  &:hover {
    .mat-mdc-button-persistent-ripple {
      &::before {
        opacity: 1;
        z-index: -1;
      }
    }
  }

  svg,
  img {
    --mdc-icon-button-icon-size: 1em;
  }

  .mat-mdc-button-touch-target {
    width: 100%;
    height: 100%;
  }

  > *:not(.mat-badge-content) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
}
